<template>
  <div class="wrap" v-loading="loading">
    <!-- <draggable
      v-model="treeData"
      group="items"
      @start="drag=true"
      @end="drag=false"
    >
      <div
        v-for="(item, index) in treeData"
        :key="index"
      >
        <div>{{ item.name }}</div>
        <draggable v-model="item.children" group="items" @start="drag=true" @end="drag=false">
          <div v-for="(child, idx) in item.children" :key="idx">
            {{ child.name }}
          </div>
        </draggable>
      </div>
    </draggable> -->
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" :key="tableKey" />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
    <c-dialog title="商城分享码" :width="1000" :showDialog.sync="dialogFormVisible">
      <template #content>
        <div class="y-c" v-loading='dialogLoading'>
          <el-image style="width: 400px; height: 400px" :src="qrCodeUrl" :preview-src-list="[qrCodeUrl]"
            class="marB20" />
          <div class="x-x">
            <div>
              {{ urlName }}
            </div>
            <div class="annotateBlue cursorP" @click="downloadSingleImage(qrCodeUrl)">
              下载
            </div>
          </div>
        </div>
      </template>
    </c-dialog>
  </div>
</template>
<script>
import CDialog from '@/components/dialogTemplate/dialog'
import draggable from "vuedraggable";
import TablePage from "@/components/tablePage";
import Dialog from "@/components/Dialog";
import {
  synCategorySortNo,
  listSimpleCategoryTree,
  categoryList,
  categoryRemove,
  categoryUpdateSortNo,
  categoryReduction,
} from "@/api/O2OMall/goods/category";
import { deepCopy } from "@/utils";
import { mallAnalysisPage, mallShare } from '@/api/O2OMall/config/sysConfig'
export default {
  name: "QueryManSet",
  components: { TablePage, Dialog, draggable, CDialog },
  data() {
    return {
      dialogLoading: false,
      qrCodeUrl: '',
      urlName: '',
      dialogFormVisible: false,
      loading: false,
      drag: false,
      treeData: [
        {
          name: "Item 1",
          children: [{ name: "Subitem 2.1" }, { name: "Subitem 2.2" }],
        },
        {
          name: "Item 2",
          children: [{ name: "Subitem 2.1" }, { name: "Subitem 2.2" }],
        },
      ],
      dialogOptions: {
        title: "",
        width: 620,
        show: false,
        type: "O2OGoodsCategory",
        formData: null,
      },
      options: {
        uuid: "3f67fbdb-95db-fd8b-3ee2-beb1267b656d",
        classTitle: true, // 多选
        mutiSelect: true, // 多选
        loading: true,
        getListApi: categoryList,
        getDataKey: (res) => {
          this.listOld = deepCopy(res.data);
          let list = deepCopy(this.handleTree(res.data, "categoryId"));
          list = this.sortTreeBySortNo(list);
          return {
            list,
          };
        },
        body: {},
        title: "商城",
        check: [], // 选中数据
        rowKey: "categoryId",
        tableTitle: true,
        hidePagination: true,
        treeProps: {
          children: "children",
          hasChildren: "hasChildren",
        },
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "del",
            label: "删除",
            icon: "el-icon-delete",
            alertText: "确认要删除选中商品分类吗？",
            type: "danger",
            btnType: "dropdown",
            trigger: "click", // 要求
            other: [
              {
                label: "回收站",
                collectionDialog: {
                  restoreListApi: this.getCategoryReduction,
                  dcollectionBody: {
                    delFlag: "1",
                  },
                },
              },
            ],
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "categoryName",
            label: "商城分类名称",
            align: "left",
            minWidth: 160,
          },
          {
            prop: "categoryNo",
            label: "商城分类编号",
            type: "link",
            click: "update",
            minWidth: 160,
          },
          {
            prop: "imageUrl",
            label: "商城分类图片",
            type: "image",
            minWidth: 100,
          },
          {
            prop: "sort",
            label: "排序",
            type: "button",
            button: [
              { text: "上移", click: "up" },
              { text: "下移", click: "down" },
            ],
            minWidth: 80,
          },
          {
            prop: "sort",
            label: "分享",
            type: "button",
            button: [
              { text: "复制链接", click: "copyPath" },
              { text: "分享码", click: "copyShare" },
            ],
            minWidth: 80,
          },
          {
            prop: "remark",
            align: "center",
            label: "备注",
            minWidth: 360,
          },
        ],
        list: [],
      },
      listOld: [],
      tableKey: Date.now(),
    };
  },
  methods: {
    async getCategoryReduction(arr) {
      await categoryReduction(arr);
      this.tableKey = Date.now();
    },
    sortTreeBySortNo(tree) {
      tree.sort((a, b) => a.sortNo - b.sortNo); // 先对顶级节点按sortNo排序
      tree.forEach((node) => {
        if (node.children && node.children.length > 0) {
          this.sortTreeBySortNo(node.children); // 递归排序子节点
        }
      });
      return tree;
    },
    updateIndex(routeprocessList) {
      routeprocessList.forEach((item, index) => {
        item.orderNum = index + 1;
      });
    },
    async handleEvent(type, row) {
      switch (type) {
        case "handleChoose":
          this.copyList = this.options.list;
          break;
        case "handleMove":
          break;
        case "dragTableEnd":
          console.log("dragTableEnd", row);
          try {
            this.loading = true;
            await synCategorySortNo(row);
            this.tableKey = Date.now();
          } finally {
            const awaitTime = (t = 100) =>
              new Promise((resolve) => setTimeout(resolve, t));
            await awaitTime(1000);
            await this.$nextTick();
            this.loading = false;
          }
          break;
        case "dragTableEndErr":
          this.$nextTick(() => {
            this.$refs.tablePage.getList();
          });
          break;
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        case "up":
          try {
            await categoryUpdateSortNo({
              categoryId: row.categoryId,
              sortType: 1,
            });
            this.$modal.msgSuccess("上移成功");
            this.$refs.tablePage.getList();
          } catch (error) { }
          break;
        case "down":
          try {
            await categoryUpdateSortNo({
              categoryId: row.categoryId,
              sortType: -1,
            });
            this.$modal.msgSuccess("下移成功");
            this.$refs.tablePage.getList();
          } catch (error) { }
          break;
        case "update":
          {
            console.log('打开弹窗');
            const options = {
              show: true,
              title: "编辑商品分类",
              click: type,
              formData: row,
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "add":
          {
            const options = {
              show: true,
              title: "新增商品分类",
              click: type,
              formData: {},
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "del": // 选中数据 Array
          const categoryIds = this.options.check.map((x) => x.categoryId);
          categoryRemove(categoryIds).then((res) => {
            this.$modal.msgSuccess("删除成功");
            this.tableKey = Date.now();
          });
          break;
        case 'copyPath':
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          try {
            const path = `subPackages/category/index?categoryId=${row.categoryId}`
            const res = await mallShare({ pageUrl: path })
            const save = function (e) {
              e.clipboardData.setData('text/plain', res.data || res.msg)
              e.preventDefault() // 阻止默认行为
            }
            document.addEventListener('copy', save) // 添加一个copy事件
            document.execCommand('copy') // 执行copy方法
            loading.close();
            this.$message({ message: '复制成功', type: 'success' })
          } catch (error) {
            loading.close();
          }
          break;
        case 'copyShare':
          this.getShare(row)
          break;
        default:
          break;
      }
    },
    async getShare(row) {
      try {
        this.dialogFormVisible = true
        this.dialogLoading = true
        this.qrCodeUrl = undefined
        this.urlName = undefined
        const res = await mallAnalysisPage({ pageUrl: `/subPackages/category/index?categoryId=${row.categoryId}` })
        this.qrCodeUrl = res.data.codeImgUrl
        this.urlName = row.categoryName
      } catch (error) {
        // this.$message.error('生成商城分享码失败')
      } finally {
        this.dialogLoading = false
      }
    },
    downloadSingleImage(imageUrl) {
      let image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = imageUrl
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob(blob => {
          let url = URL.createObjectURL(blob)
          this.download(url, '商城分享码')
          URL.revokeObjectURL(url)
        })
      }
    },
    download(href, name) {
      let eleLink = document.createElement('a')
      eleLink.download = name
      eleLink.href = href
      eleLink.click()
      eleLink.remove()
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  .dialog-footer {
    height: 80px;
  }
}
</style>
